export const environment = {
    production: true,
    name: 'production',
    version: '1.0.35',
    isWriteLogCW: true,
    firebaseConfig : {
        apiKey: "AIzaSyDGnQbsKpoI7yurfiCNAsyHIWFFTbycNJQ",
        authDomain: "athena-owl-prod.firebaseapp.com",
        projectId: "athena-owl-prod",
        storageBucket: "athena-owl-prod.appspot.com",
        messagingSenderId: "71562081737",
        appId: "1:71562081737:web:658491d4ef06967122a418",
        measurementId: "G-E6RBKE2P8Y"
    },
    // sentry_dns: 'https://abc6f6a5ac4f45f2bbfaefd08bafb5ac@o4504009167732736.ingest.sentry.io/4504009186738176',
    sentry_dns: '',
    sentry_tracing: [],
    awsCloudWatch: {
        awsAccessKey: "AKIAQMFF4KIACIQQYWP7",
        awsSecretKey: "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",
        awsRegion: "ap-southeast-1",
        logGroupName: "cognito-athena-ihunt-prod",
        logStreamName: "ihunt"
    },
    lamdaEndpointTnex: "https://dai2gv6t63.execute-api.ap-southeast-1.amazonaws.com/dev",
    host_name: 'https://cagent-api.a4b.vn/',
    host_name_login: 'https://cagent-api.a4b.vn/',
    host_name_gateway: 'https://cagent-api.a4b.vn/',
    host_name_vng_cloud: 'https://idms-gw.a4b.vn/',
    host_name_vng_cloud_x_file: 'https://xfile-gw.a4b.vn/',
    awsmobile: {
        "aws_project_region": "ap-southeast-1",
        "aws_cognito_region": "ap-southeast-1",
        "aws_user_pools_id": "ap-southeast-1_FS57FbVX3",
        "aws_user_pools_web_client_id": "26uaf1q397q6ohpicqf2l8enda",
        "oauth": {
            "domain": "isign-prod.auth.ap-southeast-1.amazoncognito.com",
            "scope": [
                "email",
                "openid",
                "phone"
            ],
            "redirectSignIn": "https://www.google.com.vn/",
            "redirectSignOut": "https://www.google.com.vn/",
            "responseType": "code"
        },
        "federationTarget": "COGNITO_USER_POOLS",
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "GIVEN_NAME",
            "FAMILY_NAME"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": [
                "REQUIRES_LOWERCASE",
                "REQUIRES_UPPERCASE",
                "REQUIRES_NUMBERS",
                "REQUIRES_SYMBOLS"
            ]
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    }
};
